@media screen and (max-width: 600px) {
  #customer,
  #menu,
  #header,
  #setup,
  #features,
  #services,
  #contact,
  #footer {
    width: 100%;
  }
}
